<template>
    <div>
        <b-modal v-model="show" size="md" centered hide-header hide-footer hide-header-close>
            <div class="p-2">
                <div class="text-center">
                    <app-heading-1 >Reject Document</app-heading-1>
                    <app-normal-text class="my-1">Are you sure you want to reject document? Give reasons</app-normal-text>
                </div>
                <div class="row my-2">
                    <div class="col-12">
                        <app-label>Reasons</app-label>
                        <b-form-textarea
                            v-model="rejectionReason"
                            rows="4"
                        ></b-form-textarea>
                    </div>
                </div>
                <div class="mt-2 d-flex justify-content-center">
                    <app-simple-button variant="outline-primary" class="mr-1"  @click="hideModal">Cancel</app-simple-button>
                    <app-simple-button variant="danger" :loading="loading" :disabled="!rejectionReason"  @click="moveNext">Reject</app-simple-button>    
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import AppNormalText from "@/components/app-components/AppNormalText"
import AppTextInput from "@/components/app-components/AppTextInput"
import AppLabel from "@/components/app-components/AppLabel"
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
import { BModal, VBModal, BFormTextarea } from 'bootstrap-vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { reject_document} from "@/apis/others/documents"
export default {
    components: {
        AppHeading1,
        AppTextInput,
        AppLabel,
        AppSimpleButton,
        BModal,
        VBModal,
        AppNormalText,
        BFormTextarea,
        vueDropzone: vue2Dropzone,
    },
    directives: {
        'b-modal': VBModal,
        
    },
    data(){
        return {
            show:false,
            rejectionReason:'',
            loading:false,
            errorMessage: '',
        }
    },
    methods:{   
        showModal(){
            this.show = true;
            this.rejectionReason=''
            this.errorMessage=''
            this.loading=false
        },
        hideModal(){
            this.show=false;
        },
        moveNext(){
            this.loading=true
            reject_document(this.$route.query.id,{rejectionReason:this.rejectionReason}).then(resp=>{
                this.loading=false;
                this.$bvToast.toast(resp.message, {
                        title: 'You have rejected the document',
                        variant: 'success',
                        solid: true,
                    })
                this.$router.push(`/documents`);
                this.show=false;
            });
        },
    },
}
</script>
<style>
  .dropzone-custom-title{
    color: #4B465C;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
  }
  .subtitle{
    color: #4B465C;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  }
  .icon-circle{
    padding: 10px;
    background-color: '#F6F7F9';
    border-radius: 50%;
  }
  .vue-dropzone{
    border: 1px dashed #DBDADE;
    border-radius: 6px;
    background-color: #ffffff;
  }
</style>